<template>
    <div class="niuniuAgreement">
        <header class="niuniuAgreementHeader">牛牛收卡转让服务协议</header>
        <pre style="white-space:pre-wrap;margin: 5px;line-height: 20px;     font-size: 15px;">

    购物卡、礼品卡、充值卡转让服务协议（以下称“本协议”）是深圳玮杰信息科技有限公司旗下牛牛收卡公众号、牛牛收卡小程序、牛牛收卡官网（以下称“牛牛收卡”）与牛牛收卡用户（以下称“用户”或“您” 就购物卡、礼品卡、充值卡转让服务（或称“本服务”）所订立的有效合约。用户通过网络页面点击确认或以其他方式选择接受本协议，即表示用户与牛牛收卡公司已达成协议并同意接受本协议的全部约定内容。 在接受本协议之前，请您仔细阅读本协议的全部内容。如果您不同意本协议的任意内容，或者无法准确理解牛牛收卡对条款的解释，请不要进行后续操作。您理解，本服务由深圳玮杰信息科技有限公司独立提供并承担全部法律责任。

一、相关定义
1.购物卡、礼品卡、充值卡: 本协议所称购物卡、礼品卡、充值卡，是平安万里通积分、壹卡会、京东E卡，苏宁卡，天猫卡、中石化加油卡、话费卡、携程卡、等等可能未提及的各大牛牛收卡公司发行并可在各大牛牛收卡充值和消费的卡片或卡密，具体以提交卡密页面为准。
2.购物卡、礼品卡、充值卡转让：本协议所称购物卡、礼品卡、充值卡转让，是指牛牛收卡提供的购物卡转让服务。用户可将其持有的购物卡、礼品卡、充值卡，转让给牛牛收卡，转让所得的金额将直接转入用户的收款账户，具体到账时间每种卡不同以卡密提交页面提示为准。具体支持的购物卡、礼品卡、充值卡卡种以用户使用本服务时的页面提示为准。

二、费用及到账时间
1.用户使用本服务需支付一定手续费，手续费金额以页面提示为准。手续费将直接从购物卡、礼品卡、充值卡面值中扣除，即用户银行账户实际到账金额为购物卡、礼品卡、充值卡面值减去手续费。
2.关于部分风险卡种到账时间及风险用户处理措施
卡种到账时间：京东E卡、大润发、沃尔玛---2小时，天虹卡--剪卡后2小时，永辉卡--6小时 请随时留意陆续更新... 本公司保留对提交纠纷、冻结卡密订单用户后续追责权利，包括不限于在牛牛旗下全渠道（公众号、小程序、官网、第三方合作app、收卡联盟成员牛牛收卡）扣除相应款项、冻结账户、永久禁用账户等措施维护牛牛收卡合法权益。 

三、牛牛收卡服务及交易规范
1.牛牛收卡为您提供二手或闲置物品的估价、转让及其他相关服务。
<span class="tagTips">2.您了解:您应对您的承诺独立承担责任。如发现您提交的物品不符合下述要求的，有权拒绝交易，并向您主张所有的损失。如商品为非法渠道物品的，平台有权依法向公安司法机关报案，将物品转交公安司法机关，并向您主张所有的损失。平台先行垫付的行为不代表对您赔偿责任的豁免，也不能视为与您共同承担法律责任的默示</span>
3.您应保证您的商品具备如下条件：
(1) 商品来源渠道合法正规，并非以诈骗、偷盗、抢夺、侵占等非法方式获得。
(2) 您对商品拥有合法、完整的处理权利。
(3) 您应承诺并保证所出售的二手商品不存在任何危及人身、财产安全的危险并不侵犯任何第三方 （含商品品牌方） 的合法权益，包括但不限于人身权、财产权、知识产权、隐私等您的商品应满足法律法规对二手商品售卖的其他要求。
(4) 商品短期内在无转让交易记录或共享于他人或其他牛牛收卡。
(5) 一旦您已与牛牛收卡确认交易并且订单完成，您将无权再要求取消订单并要求退还商品。
4.如您坚持按照出现明显输入错误或不符合常识的价格出售物品，牛牛收卡有权取消和终止此类价格错误的订单。

四、权利义务
1.使用本服务的用户，需符合微信、支付宝用户实名制度。
2.本服务有购物卡、礼品卡、充值卡面值的限额，限额以页面提示为准。用户实际提供的购物卡、礼品卡、充值卡面值(以下称“实际面值”)超过该限额的，用户将损失实际面值与限额间的差额部分，牛牛收卡不予补偿。
3.用户需按照页面提示选择正确的购物面值。如实际面值与选择的面值不一致的，可能导致延期到账或失败，由此产生的损失由用户自行承担，牛牛收卡对此不承担责任。
4.用户需遵守页面提示的每天或每天的最高转让限额，否则对于超过限额的转让，牛牛收卡有权不提供服务。
5.如因购物卡、礼品卡、充值卡密不正确或转让前已经使用等原因，导致购物卡、礼品卡、充值卡不能转让的，用户需自行解决或联系购物卡、礼品卡、充值卡发行商解决，牛牛收卡不介入处理。
6.您应保证自己填写的商品参数信息准确、真实、有效。因您填写的商品参数信息错误导致物品估价和最终成交价格不一致的，您应自行承担该后果。
7.鉴于安全性等因素的考虑，我们要求您的个人账户仅供您个人使用，每位用户应仅使用一个牛牛收卡账户。用户不得通过任何手段恶意注册账号，包括但不限于以牟利、炒作、套现、获奖等为目的多个账号注册；不得盗用其他用户账号；未经牛牛收卡明确书面许可，亦不得转让、出租或者售卖账号。如有证据证明或牛牛收卡有理由相信您存在不当注册或不当使用多个牛牛收卡账户的情形，牛牛收卡有权直接采取一切必要的措施，包括但不限于暂停、查封、冻结或关闭用户账号，取消交易，拒绝提供服务等，乃至通过诉讼形式追究用户法律责任等。如给牛牛收卡及相关方造成损失的，您还应承担赔偿责任。
8.从您的个人账户建立开始，您要负责保护好您的账户机密及所有密码。在牛牛收卡注册后，您将承担对发生在您的账户注册或密码之下的所有责任，和承担所有可能造成损失的后果，以及承担所有因此造成损失的赔偿责任。如您发现账号被盗取的，请立即与牛牛收卡客服联系。
9.您应保证不以他人资料在牛牛收卡进行注册、登录，否则由此产生的全部不利责任将全部由您自身承担，您理解并同意，牛牛收卡有权应政府部门（包括但不限于司法及行政部门）的要求向其提供您在牛牛收卡填写的注册信息和交易记录等必要信息。
10.您应当保证在使用牛牛收卡进行交易的过程中遵守国家法律法规等规范文件及牛牛收卡各项规则的规定和要求，不损害他人的合法权益，不从事与交易无关的行为;您在使用服务过程中所产生的相关费用，除牛牛收卡明确说明由牛牛收卡承担以外，均由您独自承担。
11.您需确保自己在牛牛收卡发布的内容 （包括但不限于交易评论） 是您原创或已获得有效、完全的授权，不会侵犯任何主体的肖像权、名誉权、知识产权、隐私权等合法权益，也不会侵犯法人或其他团体的商标权、商业秘密等合法权益，不违反国家法律法规及本牛牛收卡的规定。如因此引起纠纷，牛牛收卡有权直接立即删除或屏蔽该内容并要求您赔偿全部损失。您应确保您通过牛牛收卡提交、发布的全部信息（包括但不限于个人信息、商品信息、言论及所作出的行为等） 不包含以下内容：
（1）违反国家法律法规、破坏国家统一、煽动民族仇恨、民族歧视、破坏民族团结的；
（2）损害国家机关信誉、进行政治宣传或破坏国家宗教政策的；
（3）宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；
（4）侮辱、诽谤、恐吓、散播涉及他人隐私等侵害他人合法权益的；
（5）捏造或歪曲事实，从事欺诈、虚假、不准确或误导性行为，扰乱社会秩序、牛牛收卡交易秩序的；
（6）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；
（7）存在可能破坏、篡改、删除、影响牛牛收卡任何系统正常运行或未经授权秘密获取用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
（8）其他违背社会公共利益或公共道德的。

五、牛牛收卡的权利义务
1.牛牛收卡有权根据实际情况要求用户提供商品相关的有效凭证的权利（如购买发票）。
2.用户需要理解并同意，在使用服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断或无法按承诺履约。不可抗力是指不能克服、不能预见并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如地震、洪水、流行性或传染性疾病爆发等以及社会事件如战争、动乱、政府行为等。若出现上述情况时，牛牛收卡会努力在第一时间与相关单位配合，及时进行修复，但是由此给用户造成的损失在法律允许的范围内免责。
3.本协议项下，将在现有技术的基础上尽最大努力提供相应的安全措施以保障服务安全和正常运行。
<span class="tagTips">4.鉴于互联网及网络交易的特殊性，牛牛收卡无法鉴别和判断虚拟交易或正在交易或已交易的礼品卡来源、权属、真伪、等权力属性、自然属性及其他各状况。因此，用户在交易前应加以仔细辨明，并慎重考虑和评估交易可能产生的各项风险。基于此，用户须承诺充值卡密来源的合法性，对该卡密来源的合法性承担责任。若是因用户的原因，导致牛牛收卡不能正常回收该卡，用户须将已提现的转让费退还给牛牛收卡，并承担相应的责任。</span>
5.本站审核只是针对卖家提供信息进行审核，特别是虚拟商品来源我们无法审核，自审核完毕发送给买家后，即已完成<span  class="tagTips">(如若遇到盗刷卡、黑卡等任何非法形式获得的卡类，本站有权冻结该账户并将用户信息提交给相关部门。)</span>本站不对商品来源负责，亦不承担任何法律责任;
6.您必须知悉并确认：牛牛收卡因业务发展需要，拥有单方面对本服务的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销我们服务的权利，用户需承担此风险。
7.牛牛收卡应按照本协议的约定及页面提示，向用户提供购物卡、礼品卡、充值卡转让服务，并保证用户成功转让的资金及时转入其收款账户。
8.牛牛收卡有权自行全权决定以任何理由，对违反有关法律法规或本协议约定；或侵犯、妨害、威胁任何人权利或安全的内容，或者假冒他人的行为，有权依法停止传输任何相关内容，并有权依其自行判断对违反本协议的任何人士采取适当的法律。

六、用户个人信息保护及授权
您知悉并同意，为方便您使用本平台相关服务，本平台将需要存储您在使用时的必要信息，包括但不限于您的真实姓名、地址、联系方式、定位信息等。牛牛收卡承诺利用合理措施保障您个人信息的安全。

七、软件使用规范
1.关于软件的获取与更新:
（1）您可以直接从牛牛收卡网站上获取本平台，也可以从得到牛牛收卡授权的第三方获取，如果您从未经牛牛收卡授权的第三方获取本平台或与本平台名称相同的安装程序，牛牛收卡无法保证该软件能够正常使用，并对因此给您造成的损失不予负责;
（2）为了改善用户体验、完善服务内容，牛牛收卡将不断努力开发新的服务，并为您不时提供软件更新，本平台新版本发布后，旧版本的软件可能无法使用，牛牛收卡不保证旧版本平台继续可用，请您随时核对并下载最新版本。
2.除非法律允许或牛牛收卡书面许可，您使用本平台过程中不得从事下列行为:
（1）删除本平台及其副本上关于著作权的信息。
（2）对本平台进行反向工程、反向汇编、反向编译，或者有其他不当行为。
（3）对牛牛收卡拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等。
（4）对本平台或者本平台运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本平台运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经牛牛收卡授权的第三方工具/服务接入本平台和相关系统。
（5）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件方法进行运营或向公众传播，无论这些行为是否为商业目的。
（6）通过非牛牛收卡开发、授权的第三方软件、插件、外挂、系统，登录或使用牛牛收卡软件及服务，或制作、发布、传播上述工具。
（7）自行或者授权他人、第三方软件对本平台及其组件、模块、数据进行干扰。

八、违约责任
1.如果牛牛收卡发现或收到他人举报投诉您违反本协议约定的，牛牛收卡有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规账号处以包括但不限于警告、限制或禁止使用部分或全部功能、账号封禁直至注销的处罚。
2.牛牛收卡有权依据合理判断对违反有关法律法规或本协议规定的行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。
3.您理解并同意，因您违反本协议或相关服务条款的规定导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任;牛牛收卡因此遭受损失的，您也应当一并赔偿。
4.任一方对本协议任意条款的违反，均视为违约，守约方有权要求违约方承担违约责任，造成损失的，还应承担实际损失的赔偿责任。如涉诉、违约方还将承担相应的诉讼费、律师费以及一切与之相关的其他费用。

九、所有权及知识产权
1.您一旦接受本协议，即表明您主动将您在任何时间段在本平台发表的任何形式的信息内容 （包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给牛牛收卡所有，并且您同意牛牛收卡有权就任何主体侵权而单独提起诉讼。
2.本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2010年修订版《著作权法》确定）及相关法律规定的著作财产权等权利转让书面协议，其效力及于您在牛牛收卡软件上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。
2.除法律另有强制性规定外，未经牛牛收卡明确的特别书面许可任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本平台的信息内容否则，牛牛收卡有权追究其法律责任。
4.本平台所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是牛牛收卡或其内容提供者的财产，受中国和国际版权法的保护。本平台上所有内容的汇编是牛牛收卡的排他财产，受中国和国际版权法的保护。本平台上所有软件都是牛牛收卡或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。

十、其他
1.本协议适用中华人民共和国大陆地区法律。因牛牛收卡与用户就本协议的签订、履行或解释发生争议，双方应努力友好协商解决。如协商不成，牛牛收卡和用户同意由牛牛收卡住所地法院管辖审理双方的纠纷或争议。
2.本协议内容包括协议正文及所有牛牛收卡已经发布的或将来可能发布的牛牛收卡服务的使用规则。所有规则为本协议不可分割的一部分，与协议正文具有相同法律效力。


                        粤ICP备17118875号-2


        </pre>
    </div>
</template>

<script>
export default {

}
</script>

<style lang='scss'>
.niuniuAgreement {
    margin: 5px;

    .niuniuAgreementHeader {
        margin-top: 50px;
        width: 100%;
        text-align: center;
        font-size: 20px;
    }

    .tagTips {
        // background: #c30000;
        color: #ff0202;
        padding: 5px;
        font-size: 17px;
        padding: 0;
    }

}
</style>